<template>
    <div class="login" >
        <div class="login__wrapper">
            <h1 class="login__title">Войти</h1>
            <div class="login-form">
                <input class="login-form__input" placeholder="Логин" v-model.trim="login" type="text">
                <small class="text-red-600"
                       v-if="!$v.login.required && $v.login.$dirty">{{ $t('forms.validation.required') }}</small>
                <small class="text-red-600"
                       v-if="!$v.login.email && $v.login.$dirty">{{ $t('forms.validation.email') }}</small>
                <input class="login-form__input" placeholder="Пароль" type="password" v-model.trim="password">
                <small class="text-red-600"
                       v-if="!$v.password.required && $v.password.$dirty">{{ $t('forms.validation.required') }}</small>
                <div class="login-form__actions">
                    <button class="login-form__submit" @click.prevent="submit" >Войти</button>
                </div>

                <div v-if="loading" class="login-form__loader"></div>

            </div>
        </div>
        <div class="login-links">
            <div>
                <router-link tag="a" :to="{name:'Register'}" class="login-links__link">Зарегистрироваться как физическое лицо
                </router-link>
                <router-link tag="a" :to="{name:'RegisterClient'}" class="login-links__link">Зарегистрироваться как юридическое лицо
                </router-link>
                <router-link tag="a" :to="{name:'ResetPassword'}" class="login-links__link">Восстановить пароль
                </router-link>
            </div>
        </div>

    </div>
</template>

<script>

import {validationMixin} from 'vuelidate'
import {required, email} from 'vuelidate/lib/validators'

export default {
    meta:{
        title:'Авторизация'
    },
    name: "Login",
    data:()=>({
        login:'',
        password:'',
        loading:false
    }),
    mounted() {
        document.addEventListener('keypress', this.pressEnter)
    },
    beforeDestroy() {
        document.removeEventListener('keypress',this.pressEnter)
    },
    methods: {
        pressEnter(){
            if(event.keyCode === 13){
                this.submit()
            }
        },
        async submit() {
            this.$v.$touch()
            if (!this.$v.$invalid) {
                this.loading = true
                await this.$store.dispatch('auth/login', {login: this.login, password: this.password})
                this.loading = false
            }
        }

    },
    mixins: [validationMixin],
    validations: {
        password: {
            required,
        },
        login: {
            required,
            email
        },
    }
}
</script>

<style scoped>
.login{
    @apply h-screen dark:bg-gray-900 bg-white text-black dark:text-white flex justify-center flex-col
}
.login__wrapper{
    @apply sm:px-5 xl:px-24  xl:container mx-auto sm:w-full xl:w-1/3
}
.login__title{
    @apply text-5xl mb-5
}
.login-form{
    @apply dark:bg-gray-800  shadow-2xl rounded p-5
}
.dark .login-form{
    box-shadow: none;
}
.login-form__input{
    @apply text-gray-700 placeholder-gray-600 w-full px-4 py-2.5 mt-2
    transition duration-500 ease-in-out transform border-transparent
    rounded bg-gray-200
    dark:focus:bg-gray-200 outline-none;
}
.login-form__actions{
    @apply flex justify-center mt-2
}
.login-form__submit{
    @apply dark:bg-green-400 bg-blue-400 text-white px-10 py-2 rounded
}
.login-form__loader{
    @apply w-12 h-12 border-4 border-teal-600 rounded-full
}
.login-links{
    @apply flex py-5 justify-center
}
.login-links__link{
    @apply text-black block dark:text-green-400 mb-1 dark:hover:text-blue-300 hover:text-blue-400
}
</style>